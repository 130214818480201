import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DataProvider } from "./context/dataContext";
import Home from "./pages/Home/index";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import NotFound from "./pages/NotFound";

const SingleDistrict = React.lazy(() =>
  import("./pages/SingleDistrict/index.jsx")
);
const Party = React.lazy(() => import("./pages/Party/index.jsx"));
const Candidate = React.lazy(() => import("./pages/Candidate/index.jsx"));

function App() {
  return (
    <div className="App">
      <DataProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route
              path="/state/:stateId/district/:districtId"
              exact
              element={
                <React.Suspense fallback={<Loading />}>
                  <SingleDistrict />
                </React.Suspense>
              }
            />
            <Route
              path="/party/:partyId"
              exact
              element={
                <React.Suspense fallback={<Loading />}>
                  <Party />
                </React.Suspense>
              }
            />
            <Route
              path="/candidate/:candidateId"
              exact
              element={
                <React.Suspense fallback={<Loading />}>
                  <Candidate />
                </React.Suspense>
              }
            />
            <Route
              path="/assembly-candidate/:assemCandidateId"
              exact
              element={
                <React.Suspense fallback={<Loading />}>
                  <Candidate />
                </React.Suspense>
              }
            />
            <Route
              path="*"
              element={
                <React.Suspense fallback={<Loading />}>
                  <NotFound />
                </React.Suspense>
              }
            />
            <Route
              path="/404"
              element={
                <React.Suspense fallback={<Loading />}>
                  <NotFound />
                </React.Suspense>
              }
            />
          </Routes>
          <Footer />
        </Router>
      </DataProvider>
    </div>
  );
}

export default App;
