export const baseUrl = "https://cms.imagekhabar.com/api";

export const color = [
  {
    id: 1,
    color: "green",
  },
  {
    id: 4,
    color: "#a70000",
  },
  {
    id: 5,
    color: "#ff0000",
  },
  {
    id: 6,
    color: "#ff5252",
  },
  {
    id: 7,
    color: "#c30101",
  },
  {
    id: 8,
    color: "#357a38",
  },
  {
    id: 9,
    color: "#940000",
  },
  {
    id: 10,
    color: "#1c162e",
  },
  {
    id: 11,
    color: "#0198DF",
  },
  {
    id: 12,
    color: "#173F73",
  },
  {
    id: 13,
    color: "#CD0000",
  },
  {
    id: 14,
    color: "#4F0D94",
  },
  {
    id: 15,
    color: "#D94F50",
  },
];
