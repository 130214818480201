import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import { baseUrl } from "../data/data";

const PartyCard = (props) => {
  const [result, setResult] = useState();

  const url = props.assembly
    ? baseUrl + `/assprovince-${props.state?.id}-results`
    : baseUrl + `/province-${props.state?.id}-results`;

  const fetchData = async () => {
    await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setResult(data);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const winningParties = result?.filter(({ winner }) => {
    return winner !== null;
  });

  const totalWins = winningParties?.reduce((prev, curr) => {
    return prev + parseInt(curr.winner);
  }, 0);

  console.log(winningParties && winningParties);
  console.log(totalWins && totalWins);

  return (
    <div className="party-card">
      <div className="party-card-wrapper">
        <div className="card-title">
          <div className="party-name">दल</div>
          <div className="seats-detail">
          <div className="seats-won">जीत</div>
          <div className="seats-lead">अगाडि</div>

          </div>
        </div>
        {result &&
          result.slice(0, 5).map((p, i) => (
            <div className="single-row state-single-row" key={i}>
              <div className="single-row-wrapper">
                <div className="card-left">
                  <Link to={`/party/${p.id}`}>
                    <img src={p.party_img} alt={p.party_name} />
                    <span>{p.party_name}</span>
                  </Link>
                  <div>
                    <ProgressBar
                      percent={totalWins && (p.winner / totalWins) * 100}
                      partyId={p.id}
                    />
                  </div>
                </div>
                <div className="card-right-state">
                  <span className="wins">{p.winner ? p.winner : 0}</span>
                  <span className="lead">{p.lead ? p.lead : 0}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PartyCard;
