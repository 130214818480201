import React from "react";
import { ColorRing } from "react-loader-spinner";

const Loading = () => {
  return (
    <div className="loading-section">
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#ffe5e5", "#EF0000", "#B80000", "#000DB9", "#800000"]}
      />
    </div>
  );
};

export default Loading;
