import React from "react";

const NotFound = () => {
  return (
    <section className="not-found-section">
      <h1>Oops... Seems like you have entered a wrong url.</h1>
    </section>
  );
};

export default NotFound;
