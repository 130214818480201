import { m } from "framer-motion";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import dataContext from "../../context/dataContext";

const News = () => {
  const { news } = useContext(dataContext);

  console.log(news && news.map((n, i) => n.title.rendered));

  return (
    <section className="home-news-section">
      <div className="container">
        <h1 className="section-title">चुनावी समाचार</h1>
        {news &&
          news.map((n, i) => (
            <div className="single-news" key={i}>
              <div className="img-container">
                <a href={n.link} target="_blank" rel="noreferrer">
                  <img src={n.jetpack_featured_media_url} alt="ImageKhabar" />
                </a>
              </div>
              <div className="desc">
                <h1>
                  <a href={n.link} target="_blank" rel="noreferrer">
                    {n.title.rendered}
                  </a>
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: n.excerpt.rendered,
                  }}
                ></p>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default News;
