import React, { useContext } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import StatePartyCard from "./StatePartyCard";
import StateResults from "./StateResults";
import dataContext from "../context/dataContext";

const ProvinceTab = () => {
  const { state, party } = useContext(dataContext);

  return (
    <section className="province-tab-section tab-section">
      <h1 className="tab-title section-title">
        प्रदेशहरुमा दलहरुको निर्वाचन परिणाम
      </h1>
      <Tabs isManual variant="enclosed" colorScheme={"red"}>
        <TabList className="first-tab">
          <Tab fontWeight="700">प्रतिनिधिसभा</Tab>
          <Tab fontWeight="700">प्रदेशसभा</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Tabs isManual variant="enclosed" colorScheme={"red"}>
              <TabList>
                {state &&
                  state.map((p, i) => (
                    <Tab fontWeight="700" key={i}>
                      {p.province}
                    </Tab>
                  ))}
              </TabList>
              <TabPanels>
                {state &&
                  state.map((p, i) => (
                    <TabPanel key={i}>
                      <section className="party-section">
                        <div className="party-section-wrapper">
                          <div>
                            {party && (
                              <>
                                <StatePartyCard state={p} />
                                <StateResults state={p} />
                              </>
                            )}
                          </div>
                        </div>
                      </section>
                    </TabPanel>
                  ))}
              </TabPanels>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <Tabs isManual variant="enclosed" colorScheme={"red"}>
              <TabList>
                {state &&
                  state.map((p, i) => (
                    <Tab fontWeight="700" key={i}>
                      {p.province}
                    </Tab>
                  ))}
              </TabList>
              <TabPanels>
                {state &&
                  state.map((p, i) => (
                    <TabPanel key={i}>
                      <section className="party-section">
                        <div className="party-section-wrapper">
                          <div>
                            {party && (
                              <>
                                <StatePartyCard state={p} assembly={1} />
                                <StateResults state={p} assembly={1} />
                              </>
                            )}
                          </div>
                        </div>
                      </section>
                    </TabPanel>
                  ))}
              </TabPanels>
            </Tabs>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </section>
  );
};

export default ProvinceTab;
