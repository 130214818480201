import React, { useEffect, useState, useContext } from "react";
import dataContext from "../context/dataContext";
import PartyCard from "./PartyCard";
import OverallResult from "./OverallResult";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { baseUrl } from "../data/data";

const ElectionTab = () => {
  const { party } = useContext(dataContext);

  const url = baseUrl + "/party-results";
  const assUrl = baseUrl + "/assembly-total";

  const [winner, setWinner] = useState();

  const [assWinner, setAssWinner] = useState();

  const fetchWinner = async () => {
    await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setWinner(data);
        console.log(data);
      });
  };

  const fetchAssWinner = async () => {
    await fetch(assUrl)
      .then((res) => res.json())
      .then((data) => {
        setAssWinner(data);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchWinner();
    fetchAssWinner();
  }, []);

  return (
    <section className="election-tab-section tab-section">
      <h1 className="tab-title section-title">दलहरुको निर्वाचन परिणाम</h1>
      <Tabs isManual variant="enclosed" colorScheme={"red"}>
        <TabList>
          <Tab fontWeight="700">प्रतिनिधिसभा</Tab>
          <Tab fontWeight="700">प्रदेशसभा</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <section className="party-section">
              <div className="party-section-wrapper">
                <div>
                  {winner && (
                    <>
                      <PartyCard result={winner} />
                      <OverallResult result={winner} />
                    </>
                  )}
                </div>
              </div>
            </section>
          </TabPanel>
          <TabPanel>
            <section className="party-section">
              <div className="party-section-wrapper">
                <div>
                  {assWinner && (
                    <>
                      <PartyCard result={assWinner} assembly={1} />
                      <OverallResult result={assWinner} assembly={1} />
                    </>
                  )}
                </div>
              </div>
            </section>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </section>
  );
};

export default ElectionTab;
