import React from "react";
import {color} from "../data/data"

const ProgressBar = (props) => {
  const bgColor = color.find((c) => c.id === props?.partyId);


  console.log(bgColor)

  return (
    <div className="progressbar-wrapper">
      <div
        className="progressbar"
        style={{
          width: `${props.percent}%`,
          backgroundColor: `${bgColor ? bgColor.color : "#1e468a"}`,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
