import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { color } from "../data/data";

ChartJS.register(ArcElement, Tooltip, Legend);

const StateResults = (props) => {

  const data = {
    labels: props.result
      ?.filter((f) => {
        return f.winner !== null;
      }).slice(0, 5)
      .map((i) => i.party_name),
    datasets: [
      {
        label: "Number of Votes",
        data: props.result?.slice(0, 5).map((r) => r.winner),
        backgroundColor: props.result?.slice(0, 5).map((r) => color?.find((c) => c.id === r.id)).map((e) => e?.color)
      },
    ],
  };

  return (
    <div className="state-result-card">
      <div className="chart-wrapper">
        <Doughnut data={data} />
      </div>
    </div>
  );
};

export default StateResults;
