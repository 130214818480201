import React from "react";
import {
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsInstagram,
} from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="container footer-wrapper">
        <div className="footer-top">
          <div className="social-links">
            <a
              href="https://www.facebook.com/imagekhabar.com.np"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram color="#FF1F2F" />
            </a>
            <a
              href="https://www.facebook.com/imagekhabar.com.np"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook color="#166FE5" />
            </a>
            <a
              href="https://twitter.com/imagekhabar"
              target="_blank"
              rel="noreferrer"
            >
              <BsTwitter color="#1D9BF0" />
            </a>
            <a
              href="https://www.youtube.com/user/imagechannelnepal"
              target="_blank"
              rel="noreferrer"
            >
              <BsYoutube color="#FF0000" />
            </a>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="bottom-left">
            <div className="footer-logo">
              <img src="/footer.png" alt="" />
            </div>
            <div className="bottom">
              <div className="left">
                <h1>सम्पर्क</h1>
                <span className="registration-no">
                  Registration No: 219/073-74
                </span>
                <div className="location">
                  <span>Image Group Of Company</span>
                  <span>Lazimpat Road Block A,</span>
                  <span>Kathmandu 44600</span>
                </div>
                <div className="email">
                  <h1>ई–मेल:</h1>
                  <span>imagenews2@gmail.com</span>
                  <span>bhattarainami@gmail.com</span>
                </div>
              </div>
              <div className="right">
                <ul>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Image Channel HD</a>
                  </li>
                  <li>
                    <a href="#">Image FM 97.9</a>
                  </li>
                  <li>
                    <a href="#">Image News FM</a>
                  </li>
                  <li>
                    <a href="#">Image Gallery</a>
                  </li>
                  <li>
                    <a href="#">Jobs and Career</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bottom-middle">
            <h1>Department of Image Group of Company</h1>
            <p> Director: Raj Manandhar</p>
            <p> Chief Editor: Rameshwor Sapkota</p>
            <p> Advertising: Namrata Bhattarai</p>
            <p>01-4411912 /9851050659</p>
          </div>
          <div className="bottom-right">
            <div className="top">
              <h1>उपयोगी लिंकहरु</h1>
              <ul>
                <li>
                  <a href="#">देश</a>
                </li>
                <li>
                  <a href="#">राजनीति</a>
                </li>
                <li>
                  <a href="#">विचार</a>
                </li>
                <li>
                  <a href="#">अर्थ</a>
                </li>
                <li>
                  <a href="#">समाज</a>
                </li>
                <li>
                  <a href="#">विश्व</a>
                </li>
                <li>
                  <a href="#">रोचक प्रसङ्ग</a>
                </li>
                <li>
                  <a href="#">स्वास्थ्य</a>
                </li>
                <li>
                  <a href="#">साहित्य</a>
                </li>
                <li>
                  <a href="#">मनोरञ्जन</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-container">
        <div className="container">
          <div className="copyright-section">
            ©2022 Imagekhabar – Online News Portal of Nepal |
          </div>
          <div className="developed-by-section">
            <span>Designed & Devevloped by: </span>
            <a href="https://appharu.com/" target="_blank" rel="noreferrer">
              Appharu
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
