import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import dataContext from "../context/dataContext";

const PartyCard = (props) => {
  const { party} = useContext(dataContext);

  const winningParties = props.result?.filter(({ winner }) => {
    return winner !== null;
  });

  const totalWins = winningParties?.reduce((prev, curr) => {
    return prev + parseInt(curr.winner);
  }, 0);

  console.log(winningParties && winningParties);
  console.log(totalWins && totalWins);

  return (
    <div className="party-card">
      <div className="party-card-wrapper">
        <div className="card-title">
          <div className="party-name">दल</div>
          <div className="seats-won">जीत</div>
        </div>
        {props.result &&
          props.result.slice(0, 5).map((p, i) => (
            <div className="single-row" key={i}>
              <div className="single-row-wrapper">
                <div className="card-left">
                  <Link to={`/party/${p.id}`}>
                    <img
                      src={party?.find((j) => j.id === p.id)?.party_img}
                      alt={p.party_name}
                    />
                    <span>{p.party_name}</span>
                  </Link>
                  <div>
                    <ProgressBar
                      percent={totalWins && (p.winner / totalWins) * 100}
                      partyId={p.id}
                    />
                  </div>
                </div>
                <div className="card-right">
                  <span>{p.winner ? p.winner : 0}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PartyCard;
