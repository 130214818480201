import React, { useContext } from "react";
import NepalStateMap from "../../components/NepalStateMap";
import SearchForm from "../../components/SearchForm";
import ProvinceTab from "../../components/ProvinceTab";
import ElectionTab from "../../components/ElectionTab";
import { ColorRing } from "react-loader-spinner";

import dataContext from "../../context/dataContext";
import "./../../css/home.css";
import Loading from "../../components/Loading";
import News from "./News";

const Home = () => {
  const { loading } = useContext(dataContext);

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <SearchForm />
          <div className="container">
            <NepalStateMap />
            <ElectionTab />
            <ProvinceTab />
            <News />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Home;
