import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { color, baseUrl } from "../data/data";

ChartJS.register(ArcElement, Tooltip, Legend);

const StateResults = (props) => {

  const [result, setResult] = useState();

  const url = props.assembly
    ? baseUrl + `/assprovince-${props.state?.id}-results`
    : baseUrl +  `/province-${props.state?.id}-results`;

  const fetchData = async () => {
    await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setResult(data);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = {
    labels: result
      ?.slice(0, 5)
      .filter((f) => {
        return f.winner !== null;
      })
      .map((i) => i.party_name),
    datasets: [
      {
        label: "Number of Votes",
        data: result?.slice(0, 5).map((r) => r.winner),
        backgroundColor: result?.slice(0, 5).map((r) => color?.find((c) => c.id === r.id)).map((e) => e?.color)
      },
    ],
  };

  return (
    <div className="state-result-card">
      <div className="chart-wrapper">
        <Doughnut data={data} />
      </div>
    </div>
  );
};

export default StateResults;
